// @generated by protoc-gen-es v2.0.0 with parameter "target=ts"
// @generated from file wavin/blaze/v1/device_config.proto (package wavin.blaze.v1, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_buf_validate_validate } from "../../../buf/validate/validate_pb";
import type { Timestamp } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_timestamp } from "@bufbuild/protobuf/wkt";
import type { DeviceConfig as DeviceConfig$1 } from "../../sentio/v1/device_config_pb";
import { file_wavin_sentio_v1_device_config } from "../../sentio/v1/device_config_pb";
import type { DeviceConfigSource } from "../../sentio/v1/device_config_source_pb";
import { file_wavin_sentio_v1_device_config_source } from "../../sentio/v1/device_config_source_pb";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file wavin/blaze/v1/device_config.proto.
 */
export const file_wavin_blaze_v1_device_config: GenFile = /*@__PURE__*/
  fileDesc("CiJ3YXZpbi9ibGF6ZS92MS9kZXZpY2VfY29uZmlnLnByb3RvEg53YXZpbi5ibGF6ZS52MSLdAQoMRGV2aWNlQ29uZmlnEgwKBG5hbWUYASABKAkSPgoJdGltZXN0YW1wGAIgASgLMhouZ29vZ2xlLnByb3RvYnVmLlRpbWVzdGFtcEIPukgMyAEBsgEGSgIICjgBEi8KBnNlbnRpbxgDIAEoCzIdLndhdmluLnNlbnRpby52MS5EZXZpY2VDb25maWdIABI8Cg1zZW50aW9fc291cmNlGAogASgLMiMud2F2aW4uc2VudGlvLnYxLkRldmljZUNvbmZpZ1NvdXJjZUgBQgYKBGRhdGFCCAoGc291cmNlQoEBChJjb20ud2F2aW4uYmxhemUudjFCEURldmljZUNvbmZpZ1Byb3RvUAGiAgNXQliqAg5XYXZpbi5CbGF6ZS5WMcoCDldhdmluXEJsYXplXFYx4gIaV2F2aW5cQmxhemVcVjFcR1BCTWV0YWRhdGHqAhBXYXZpbjo6QmxhemU6OlYxYgZwcm90bzM", [file_buf_validate_validate, file_google_protobuf_timestamp, file_wavin_sentio_v1_device_config, file_wavin_sentio_v1_device_config_source]);

/**
 * DeviceConfig is a snapshot in time of the config and metrics of a device including connected peripherals (atomic)
 * The device config contains all the topology information at that point in time.
 * Data contracts are automatically versioned by following proto spec compatibility
 *
 * @generated from message wavin.blaze.v1.DeviceConfig
 */
export type DeviceConfig = Message<"wavin.blaze.v1.DeviceConfig"> & {
  /**
   * DeviceConfig resource name, format: devices/{device}/configs/{config}
   *
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 2;
   */
  timestamp?: Timestamp;

  /**
   * @generated from oneof wavin.blaze.v1.DeviceConfig.data
   */
  data: {
    /**
     * @generated from field: wavin.sentio.v1.DeviceConfig sentio = 3;
     */
    value: DeviceConfig$1;
    case: "sentio";
  } | { case: undefined; value?: undefined };

  /**
   * Source of the mapped data
   *
   * @generated from oneof wavin.blaze.v1.DeviceConfig.source
   */
  source: {
    /**
     * @generated from field: wavin.sentio.v1.DeviceConfigSource sentio_source = 10;
     */
    value: DeviceConfigSource;
    case: "sentioSource";
  } | { case: undefined; value?: undefined };
};

/**
 * Describes the message wavin.blaze.v1.DeviceConfig.
 * Use `create(DeviceConfigSchema)` to create a new message.
 */
export const DeviceConfigSchema: GenMessage<DeviceConfig> = /*@__PURE__*/
  messageDesc(file_wavin_blaze_v1_device_config, 0);

