// @generated by protoc-gen-es v2.0.0 with parameter "target=ts"
// @generated from file wavin/ignite/v1/user.proto (package wavin.ignite.v1, syntax proto3)
/* eslint-disable */

import type { GenEnum, GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { enumDesc, fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_buf_validate_validate } from "../../../buf/validate/validate_pb";
import type { Timestamp } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_timestamp } from "@bufbuild/protobuf/wkt";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file wavin/ignite/v1/user.proto.
 */
export const file_wavin_ignite_v1_user: GenFile = /*@__PURE__*/
  fileDesc("Chp3YXZpbi9pZ25pdGUvdjEvdXNlci5wcm90bxIPd2F2aW4uaWduaXRlLnYxItYFCgRVc2VyEh4KBG5hbWUYASABKAlCELpIDcgBAXIIOgZ1c2Vycy8SDQoFZW1haWwYAiABKAkSWQoNbGFuZ3VhZ2VfY29kZRgDIAEoCUI9ukg6cjhSAmNzUgJkYVICZGVSAmVuUgJlc1ICZnJSAml0UgJsdlICbmxSAm5vUgJwbFICZmlSAnN2UgJ0ckgAiAEBEiMKFnRlcm1zX2FjY2VwdGVkX3ZlcnNpb24YBCABKAlIAYgBARIZCgxjb21wYW55X25hbWUYBSABKAlIAogBARIXCgpmaXJzdF9uYW1lGAYgASgJSAOIAQESFgoJbGFzdF9uYW1lGAcgASgJSASIAQESHQoQc3RyZWV0X2FkZHJlc3NfMRgIIAEoCUgFiAEBEh0KEHN0cmVldF9hZGRyZXNzXzIYCSABKAlIBogBARIVCgh6aXBfY29kZRgKIAEoCUgHiAEBEhEKBGNpdHkYCyABKAlICIgBARIUCgdjb3VudHJ5GAwgASgJSAmIAQESKAoHZGV2aWNlcxgNIAMoCzIXLndhdmluLmlnbml0ZS52MS5EZXZpY2USJAoFcm9sZXMYDiADKA4yFS53YXZpbi5pZ25pdGUudjEuUm9sZRJBChhpbnN0YWxsZXJfYWdyZWVtZW50X3RpbWUYDyABKAsyGi5nb29nbGUucHJvdG9idWYuVGltZXN0YW1wSAqIAQFCEAoOX2xhbmd1YWdlX2NvZGVCGQoXX3Rlcm1zX2FjY2VwdGVkX3ZlcnNpb25CDwoNX2NvbXBhbnlfbmFtZUINCgtfZmlyc3RfbmFtZUIMCgpfbGFzdF9uYW1lQhMKEV9zdHJlZXRfYWRkcmVzc18xQhMKEV9zdHJlZXRfYWRkcmVzc18yQgsKCV96aXBfY29kZUIHCgVfY2l0eUIKCghfY291bnRyeUIbChlfaW5zdGFsbGVyX2FncmVlbWVudF90aW1lIi4KBkRldmljZRIKCgJpZBgBIAEoCRIYChByZWdpc3RyYXRpb25fa2V5GAIgASgJKq8BCgRSb2xlEhQKEFJPTEVfVU5TUEVDSUZJRUQQABISCg5ST0xFX0lBTV9BRE1JThABEhgKFFJPTEVfVEVMRU1FVFJZX0FETUlOEAISFgoSUk9MRV9DT05GSUdfRURJVE9SEAMSFwoTUk9MRV9GSVJNV0FSRV9BRE1JThAEEhgKFFJPTEVfRklSTVdBUkVfRURJVE9SEAUSGAoUUk9MRV9GSVJNV0FSRV9WSUVXRVIQBkJ+ChNjb20ud2F2aW4uaWduaXRlLnYxQglVc2VyUHJvdG9QAaICA1dJWKoCD1dhdmluLklnbml0ZS5WMcoCD1dhdmluXElnbml0ZVxWMeICG1dhdmluXElnbml0ZVxWMVxHUEJNZXRhZGF0YeoCEVdhdmluOjpJZ25pdGU6OlYxYgZwcm90bzM", [file_buf_validate_validate, file_google_protobuf_timestamp]);

/**
 * @generated from message wavin.ignite.v1.User
 */
export type User = Message<"wavin.ignite.v1.User"> & {
  /**
   * Resource name of users, format: users/{usersID}
   *
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * @generated from field: string email = 2;
   */
  email: string;

  /**
   * @generated from field: optional string language_code = 3;
   */
  languageCode?: string;

  /**
   * @generated from field: optional string terms_accepted_version = 4;
   */
  termsAcceptedVersion?: string;

  /**
   * @generated from field: optional string company_name = 5;
   */
  companyName?: string;

  /**
   * @generated from field: optional string first_name = 6;
   */
  firstName?: string;

  /**
   * @generated from field: optional string last_name = 7;
   */
  lastName?: string;

  /**
   * @generated from field: optional string street_address_1 = 8;
   */
  streetAddress1?: string;

  /**
   * @generated from field: optional string street_address_2 = 9;
   */
  streetAddress2?: string;

  /**
   * @generated from field: optional string zip_code = 10;
   */
  zipCode?: string;

  /**
   * @generated from field: optional string city = 11;
   */
  city?: string;

  /**
   * @generated from field: optional string country = 12;
   */
  country?: string;

  /**
   * @generated from field: repeated wavin.ignite.v1.Device devices = 13;
   */
  devices: Device[];

  /**
   * @generated from field: repeated wavin.ignite.v1.Role roles = 14;
   */
  roles: Role[];

  /**
   * @generated from field: optional google.protobuf.Timestamp installer_agreement_time = 15;
   */
  installerAgreementTime?: Timestamp;
};

/**
 * Describes the message wavin.ignite.v1.User.
 * Use `create(UserSchema)` to create a new message.
 */
export const UserSchema: GenMessage<User> = /*@__PURE__*/
  messageDesc(file_wavin_ignite_v1_user, 0);

/**
 * @generated from message wavin.ignite.v1.Device
 */
export type Device = Message<"wavin.ignite.v1.Device"> & {
  /**
   * uniquely identifies an ios or android device
   *
   * @generated from field: string id = 1;
   */
  id: string;

  /**
   * registration key that is used to send notifications to a device
   *
   * @generated from field: string registration_key = 2;
   */
  registrationKey: string;
};

/**
 * Describes the message wavin.ignite.v1.Device.
 * Use `create(DeviceSchema)` to create a new message.
 */
export const DeviceSchema: GenMessage<Device> = /*@__PURE__*/
  messageDesc(file_wavin_ignite_v1_user, 1);

/**
 * @generated from enum wavin.ignite.v1.Role
 */
export enum Role {
  /**
   * @generated from enum value: ROLE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * View & assign roles of users.
   *
   * @generated from enum value: ROLE_IAM_ADMIN = 1;
   */
  IAM_ADMIN = 1,

  /**
   * View telemetry data for all devices.
   *
   * @generated from enum value: ROLE_TELEMETRY_ADMIN = 2;
   */
  TELEMETRY_ADMIN = 2,

  /**
   * View & edit configuration for claimed devices.
   *
   * @generated from enum value: ROLE_CONFIG_EDITOR = 3;
   */
  CONFIG_EDITOR = 3,

  /**
   * Release firmware version GA for all devices.
   *
   * @generated from enum value: ROLE_FIRMWARE_ADMIN = 4;
   */
  FIRMWARE_ADMIN = 4,

  /**
   * List & download GA & Beta firmware releases. Upload firmware. Release to claimed devices.
   *
   * @generated from enum value: ROLE_FIRMWARE_EDITOR = 5;
   */
  FIRMWARE_EDITOR = 5,

  /**
   * List & download GA firmware releases.
   *
   * @generated from enum value: ROLE_FIRMWARE_VIEWER = 6;
   */
  FIRMWARE_VIEWER = 6,
}

/**
 * Describes the enum wavin.ignite.v1.Role.
 */
export const RoleSchema: GenEnum<Role> = /*@__PURE__*/
  enumDesc(file_wavin_ignite_v1_user, 0);

